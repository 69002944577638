import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isLoggedIn, postCheck } from "./utils/auth"
import { isBrowser } from './utils/Functions';

const PrivateRoute = ({ component: Component, path, location, ...rest }) => {
  const loginCheck = isLoggedIn()
  //console.log('loginCheck',loginCheck)
  if (isBrowser && loginCheck !== true) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/login/`)
    return null
  }
  if (loginCheck) {
    postCheck()
    return (
      <Component {...rest} />
    )
  }

  return null
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
