import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Dash from "../components/Dash/index"
import PrivateRoute from "../components/Dash/PrivateRoute"
import HeaderNav from '../components/HeaderNav';

class Secure extends Component {
  state = {
    navActive: false,
  }
  render() {
    const {
      navActive,
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      secureNavigation,
      wordpressWpSettings
    } = data;
    return (
      <Layout>
        <Router className="rout">
          <PrivateRoute path="/members/" component={Dash} navigation={secureNavigation} wordpressUrl={wordpressWpSettings.wordpressUrl} siteUrl={wordpressWpSettings.siteUrl} />
          <PrivateRoute path="/members/:splat" component={Dash} navigation={secureNavigation} wordpressUrl={wordpressWpSettings.wordpressUrl} siteUrl={wordpressWpSettings.siteUrl} />
        </Router>
      </Layout>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SecurePageQuery {
        wordpressWpSettings {
          wordpressUrl
          siteUrl
        }
        secureNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "members-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
            }
          }
        }
      }
    `}
    render={data => <Secure data={data} {...props} />}
  />
)
