import React from "react"
import axios from 'axios'
import { navigate } from "gatsby"
import { getCurrentUser } from './utils/auth'
import { Loading } from './images'
import * as AcfLayout from '../Acf';
import GatsbyLink from '../GatsbyLink';
import './index.scss'

const AcfComponent = ({ location, componentName, item, pageContext, wordpressUrl, siteUrl }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.acf_fc_layout ? item.acf_fc_layout : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        wordpressUrl={wordpressUrl}
        siteUrl={siteUrl}
        {...item}
      />
    );
  }

};

class Dash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: null,
      loading: true,
      error: null
    }
  }
  loadData(slugPass = null) {
    this.setState({loading: true})
    //console.log('this.props.splat:',this.props.splat)
    const user = getCurrentUser()
    const splat = this.props.splat ? this.props.splat : 'members'
    const slug = slugPass ? slugPass : splat
    const url = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/pages?slug=${slug}&_embed`
    axios.get(url)
      .then(res => {
        if (res.data[0]) {
          //console.log('res.data[0]:',res.data[0])
          this.setState({
            pageData: res.data[0],
            loading: false
          })
        }else{
          this.setState({
            loading: false,
            error: true
          })
        }
      })
      .catch((err) => {
        console.log('error:', err)
        this.setState({
          loading: false,
          error: true
        })
      })
  }
  componentDidMount() {
    this.loadData()
  }

  reloadData = (event, slug) => {
    //console.log('Reload Data')
    this.loadData(slug)
  }

  render() {
    const { pageData, loading  } = this.state
    const { pageContext, location, navigation, siteUrl } = this.props
    if ( loading ) {
      return (
        <div className="secure"><div className="loading"><Loading/></div></div>
      )
    }

    const { acf = {} } = pageData;
    const { layout } = acf;
    return (
      <div className="secure">
        <div className="secure-navigation">
          <div className="inner">
            <ul >
            {navigation && navigation.items.map((item, index) => (
              <li key={index}><GatsbyLink to={item.url} onClick={event => this.reloadData( event, item.slug )} dangerouslySetInnerHTML={{__html: item.title}} /></li>
            ))}
            </ul>
          </div>
        </div>
        {layout && layout.map((item, index) => {
          if (!item.acf_fc_layout) return null;
          const layoutComponentName = item.acf_fc_layout;
          //console.log('layoutComponentName:',layoutComponentName)
          return (
            <AcfComponent
              key={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
              wordpressUrl={process.env.GATSBY_WP_ADDRESS}
              siteUrl={siteUrl}
            />
          );
        })}
      </div>
    )
  }
}
export default Dash
